<template>
  <div v-if="imageUrls.length">
    <carousel
      class="mx-xs-0 mx-sm-3 mx-md-0"
      :scrollPerPage="$vuetify.breakpoint.lgAndUp"
      :perPage="carouselSlideSize"
      :navigationEnabled="!isSingleImageSize"
      :paginationEnabled="isSingleImageSize"
      paginationPosition="bottom-overlay"
      :touchDrag="true"
      :mouseDrag="true"
      :navigationNextLabel="navigationNext"
      :navigationPrevLabel="navigationPrev"
    >
      <template v-if="enableLoyalty">
        <PointsRewardComponent
          @show="toggleModal"
          :points-value-text="minimumPointsEarned"
        />
        <PointsRewardModal @hide="toggleModal" :isModalOpen="isModalActive" />
      </template>
      <slide
        :class="[!isSingleImageSize ? 'py-1' : '', getImgPadding]"
        v-for="(imageUrl, index) in carouselImageList"
        :key="index"
        @slide-click="onCarouselImageClick(index)"
      >
        <v-card :elevation="imgElevation">
          <v-img
            alt="carouselImage"
            class="white--text align-end"
            :src="imageUrl"
            :max-height="$vuetify.breakpoint.mdAndUp ? maxHeight : 220"
            :aspect-ratio="dynamicAspectRatio"
            data-cy="carousel-image"
          >
            <template
              v-if="
                shouldAddOverlayToLastImage &&
                  index == imageLimitInSingleImageMode - 1
              "
            >
              <v-overlay
                absolute
                opacity="0.95"
                class="display-3 font-weight-bold"
              >
                + {{ extraImagesCount }}
              </v-overlay>
            </template>

            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align-self="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="primary lighten-1" />
              </v-row>
            </template>
          </v-img>
        </v-card>
      </slide>
    </carousel>

    <vue-gallery
      :images="imageUrls"
      :index="clickedImageIndex"
      @close="clickedImageIndex = null"
    />
  </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
import VueGallery from 'vue-gallery';
import PointsRewardComponent from '@/modules/common/components/PointsRewardComponent';
import PointsRewardModal from '@/modules/common/components/PointsRewardModal';
import ProductDetailsMixins from '@/modules/product/booking/mixins/ProductDetailsMixins';

export default {
  mixins: [ProductDetailsMixins],
  components: {
    PointsRewardComponent,
    PointsRewardModal,
    Carousel,
    Slide,
    VueGallery,
  },

  props: {
    imageUrls: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: Number,
      default: 410,
    },
    singleImageMode: {
      type: Boolean,
      default: false,
    },
    imageLimitInSingleImageMode: {
      type: Number,
      default: 5,
    },
    imgDistance: {
      type: String,
      default: '0',
    },
    imgElevation: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      dialog: false,
      navigationNext:
        '<i class="mdi mdi-chevron-right" style="font-size:32px;color:rgba(0,0,0,0.8)"></i>',
      navigationPrev:
        '<i class="mdi mdi-chevron-left" style="font-size:32px;color:rgba(0,0,0,0.8)"></i>',
      clickedImageIndex: null,
      isModalActive: false,
    };
  },

  computed: {
    getImgPadding() {
      return this.imgDistance ? `px-${Math.floor(this.imgDistance / 4)}` : '';
    },

    minimumPointsEarned() {
      if (!this.hasDetails) return '0';
      else {
        return this.$t('product.from', {
          price: this.detailsModel.minimumEarningValue.value,
        });
      }
    },

    carouselSlideSize() {
      let pageSize = 2;
      if (this.singleImageMode || this.$vuetify.breakpoint.smAndDown) {
        pageSize = 1;
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        pageSize = 3;
      }
      return this.imageUrls.length > pageSize
        ? pageSize
        : this.imageUrls.length;
    },

    isSingleImageSize() {
      return this.carouselSlideSize === 1;
    },

    dynamicAspectRatio() {
      let ratio = 0.92;
      if (
        !this.singleImageMode &&
        this.$vuetify.breakpoint.lgAndUp &&
        this.carouselSlideSize < 3
      ) {
        ratio = this.carouselSlideSize === 2 ? 4 / 3 : 16 / 9;
      }
      return ratio;
    },

    shouldAddOverlayToLastImage() {
      return (
        this.isSingleImageSize &&
        this.imageUrls.length > this.imageLimitInSingleImageMode
      );
    },

    carouselImageList() {
      return this.shouldAddOverlayToLastImage
        ? this.imageUrls.slice(0, this.imageLimitInSingleImageMode)
        : this.imageUrls;
    },

    extraImagesCount() {
      return this.imageUrls.length - this.imageLimitInSingleImageMode + 1;
    },

    enableLoyalty() {
      return this.$store.getters['featureFlags/enableLoyalty'];
    },
  },

  methods: {
    onCarouselImageClick(imageIndex) {
      this.clickedImageIndex = imageIndex;
    },

    toggleModal() {
      this.isModalActive = !this.isModalActive;
    },
  },

  mounted() {
    document.querySelectorAll('h3.title').forEach((elem) => elem.remove());
  },
};
</script>

<style lang="scss" scoped>
button.VueCarousel-navigation-button:focus {
  outline: none !important;
}

button.VueCarousel-dot:focus {
  outline: none !important;
}

::v-deep .blueimp-gallery {
  > .title,
  > .description {
    display: none !important;
  }
}

.VueCarousel-slide {
  cursor: pointer;
}
</style>
