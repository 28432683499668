<template>
  <v-snackbar
    v-model="flightAlert"
    :timeout="timeout"
    color="var(--v-grey1-base)"
    elevation="4"
    class="flight-alert"
    top
  >
    <span class="black--text">{{ $t('flight_destination_changed') }} </span>
    <a @click.prevent="searchCity">{{ linkText }}</a>
    <template v-slot:action="{ attrs }">
      <v-btn
        type="button"
        icon
        v-bind="attrs"
        color="black"
        @click="flightAlert = false"
        aria-label="button for flightAlert"
        title="button for flightAlert"
      >
        <v-icon aria-hidden="true">
          mdi-window-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import { mapboxService } from '@/core/services/MapboxService';

export default {
  name: 'FlightAlert',
  mixins: [SelectedContextFlightMixins],
  props: {
    isDetailsPage: {
      type: Boolean,
      default: false,
    },
    linkText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      flightAlert: false,
      timeout: 7000,
      routeName: '',
    };
  },
  computed: {
    flightCity() {
      return this.selectedContextFlight?.destination.city;
    },
  },
  methods: {
    searchCity() {
      if (this.isDetailsPage) {
        this.flightAlert = false;
        this.goToRoute();
      } else {
        this.flightAlert = false;
        this.$emit('searchCity');
      }
    },
    showAlert(routeName = '') {
      this.routeName = routeName;
      this.flightAlert = true;
    },
    async goToRoute() {
      const res = await mapboxService.getCities(
        this.selectedContextFlight.destination.city
      );
      if (res.features && res.features.length > 0) {
        this.$router
          .push({
            name: this.routeName,
            query: {
              city: res.features[0].text_en,
              lng: res.features[0].center[0],
              lat: res.features[0].center[1],
            },
          })
          .catch((err) => {
            return err;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.flight-alert {
  padding-top: 160px !important;
}
</style>
