<script>
export default {
  name: 'ProductDetailsSafetyMeasuresMixins',
  methods: {
    getSafetyMeasuresValue(label, { returnLabel } = { returnLabel: false }) {
      if (!this.hasModel) return null;
      const { safetyMeasures } = this.model;
      if (safetyMeasures) {
        const info = safetyMeasures.find((info) => {
          return (
            info.label === label && this.checkSafetyMeasuresValue(info.value)
          );
        });
        if (info) {
          return returnLabel ? info : info.value;
        }
      }
      return null;
    },
    checkSafetyMeasuresValue(value) {
      return value && value !== 'false';
    },
  },
  computed: {
    hasSafetyMeasures() {
      if (!this.hasModel) return false;
      const { safetyMeasures } = this.model;
      return safetyMeasures !== undefined && !!safetyMeasures.length;
    },
    faceMask() {
      return this.getSafetyMeasuresValue('face_mask');
    },
    cashAccepted() {
      return this.getSafetyMeasuresValue('cash_accepted');
    },
    gloves() {
      return this.getSafetyMeasuresValue('gloves');
    },
    hasHandSanitizer() {
      return this.getSafetyMeasuresValue('has_hand_sanitizer_available');
    },
    hasIntensifiedCleaning() {
      return this.getSafetyMeasuresValue('has_intensified_cleaning');
    },
    hasReducedCapacity() {
      return this.getSafetyMeasuresValue('has_reduced_capacity');
    },
    hasSocialDistancing() {
      return this.getSafetyMeasuresValue('has_social_distancing');
    },
    hasTemperatureChecks() {
      return this.getSafetyMeasuresValue('has_temperature_checks');
    },
    reducedCapacitySize() {
      return this.getSafetyMeasuresValue('reduced_capacity');
    },
    onlineTicketingOnly() {
      return this.getSafetyMeasuresValue('online_ticketing_only');
    },
    limitedDuration() {
      return this.getSafetyMeasuresValue('limited_duration');
    },
    waitingTime() {
      return this.getSafetyMeasuresValue('waiting_time');
    },
    otherSafetyInfo() {
      return this.getSafetyMeasuresValue('other_info');
    },
  },
};
</script>
