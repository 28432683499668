<template>
  <label class="points" v-on:click.prevent="showRewardInfoModal">
    {{ pointsValueText }}+ pts
  </label>
</template>

<script>
export default {
  name: 'PointsRewardComponent',
  props: {
    pointsValueText: [String, Number],
  },
  methods: {
    showRewardInfoModal() {
      this.$emit('show');
    },
  },
};
</script>

<style lang="scss" scoped>
.points {
  display: inline-flex;
  position: absolute;
  width: auto;
  left: 2%;
  bottom: 3%;
  background-color: coral;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  padding: 2px 5px;
  height: 22px;
  z-index: 10;
}
</style>
